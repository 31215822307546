.App {
  text-align: center;
  width: 100vw;
  padding: 0;
  margin : 0;
  
}

.form{
  margin-top: 2vw;
}
body{
  padding-bottom: 300px;
  width: 100%;
}
.dropbtn {

  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-links {
  background-color:"#0d6efd"
}
/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
/* The container <div> - needed to position the dropdown content */


.row {
  justify-content: space-around;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

h1 {
  text-align: center;
}
.images {
            display: flex;
            flex-wrap: wrap;
            /*margin: 0 50px;
            padding: 20px;*/
        }
  
        .photo {
            max-width: 31.333%;
            padding: 0 10px;
            height: 240px;
        }
  
        .photo img {
            width: 100%;
            height: 100%;
        }


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {

  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.woodland{
  font-family: 'Times New Roman', Times, serif;
  text-decoration: underline, chocolate;
  font-weight: 900;
  font-size: 2.5em; 
}
.table{

  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5fHTkU4_lY-Mp2rNaBLExYromjP8ZJiMzDw&usqp=CAU"); */
   /* background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbyqVANMMnZOx7uGDsftkkeRI3DQxtmjfTijDpVNe1SAGwJktf3GpS5zhnPv3tLNX419I&usqp=CAU);  */
  /* background-image: url(https://i.pinimg.com/originals/60/6a/6e/606a6e2eab2ca9da6e573dab68c2af46.jpg); */
  /* margin: 15px 0 0 15px; */
  /* background-image: url(https://assets.hongkiat.com/uploads/wood-textures/simple-wood-texture.jpg);
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbyqVANMMnZOx7uGDsftkkeRI3DQxtmjfTijDpVNe1SAGwJktf3GpS5zhnPv3tLNX419I&usqp=CAU); */
  /* original bg
   background-image: url(https://i.pinimg.com/originals/60/6a/6e/606a6e2eab2ca9da6e573dab68c2af46.jpg); */
  
  
   background-image: url("./components/common/images/bgimg.jpg"); 
/*   3rd
  background-image: url(https://htmlcolorcodes.com/assets/images/colors/light-gray-color-solid-background-1920x1080.png);  */
     
  /* padding: 20px 0 0 20px;
  padding-left: 20px; */
  opacity: 1.9;
  background-color: white;
}
.furniture-image{
  padding: 15px;
}
.products{
  text-align: left;
}
.card-da-mukabla{
  text-align: right;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size:13px;
  font-weight:500;
}

/* .card-da-mukabla-da-image : hover{
  size: 1.5em;
  width: 1.5em;
  height: 1.5em;
} */
.card-da-mukabla-da-title{
  font-family:serif;

}
.card-da-mukabla-da-image {
  
  border-radius: 4px;
    background: #fff;
    box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  padding: 14px 80px 18px 36px;
  cursor:pointer;

}
.card-da-mukabla-da-image:hover{
  transform: scale(1.25);
  transition:0.5s ease-in;
  /* box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06); */
  /* padding: 0;

  box-shadow: 0; */
}
.card-da-mukabla-da-title:hover{
  font-size: 110%;
  font-weight: bold;
  cursor: pointer;
}
.EXCUSES:hover {
  font-size: 110%
  ;
  font-weight:bolder;
  cursor: pointer;
  
}

.ab{
  /* background-color: #fffffffb;         */
     
    
    
    
     
      background-image: linear-gradient(  white , rgb(161, 161, 167) );  
    
     /* 3rd bg
      background-image: linear-gradient(rgb(204,202,202) 70%, rgb(179, 178, 178) ); */
   
   margin-right: 18px;

}
  
  
/* }

.switch mode button{
  cursor: pointer;
  border: 1px solid #555;
  text-align: right;
  padding: 5px;
  margin-left: 8px;
  background-color:silver;
}
.dark{
  background-color: #222;
  color: #e6e6e6;
} */
/* footer{

  top: 100%;
  position: sticky;
  background-color: #faf3f3;
  text-align: center;
  font-weight: bolder;

} */
.products{
  height: 150;
font-size:8px;
font-weight: normal;
font-family:fantasy;
  width: 150;
  margin: 0 auto;
}
.nav-prod{
  font-size: 12px;
  margin-bottom: 10vh;
}


.site-footer
{
  
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#2c2828;
  top: 100%;
  width: 100%;
  height: 200px;
  /* margin-bottom: -50.1vh;
  background-image:linear-gradient(140deg, #ad830f 75%, #f7fc00e7 50%, #ad830f 75%); 
  /* margin-top: 100px;

  margin-bottom: -335px; */
  /* background-color: rgb(204, 125, 22); */
  /* background-image: url("./components/common/images/footerimg.jpg"); */
  /* background-image: url("https://ak.picdn.net/shutterstock/videos/24223843/thumb/1.jpg"); */
  background-image: linear-gradient(  gray ,silver 90%  );
 
/*   
  background-image:linear-gradient(140deg, #ad830f 75%, #f7fc00e7 50%, #ad830f 75%);  */
  position: sticky;
  
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:rgb(46, 41, 41);
  font-weight: bolder;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px;
  padding-left: 300px;
  text-align: center;
   margin: 0;
   display: inline;
   align-content: center;
  


  
  
}
.quicky{
  
  text-align: center;
}

.site-footer a
{
  color:#1d1400;
}
.row{
  justify-content: center;
  
}
.row-1{
  display: flex;
  justify-content: center;
  margin-left: -10%;
}
.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block;
  margin-left:53%;
  text-align: left;
  text-decoration: none;
  
}
.footer-links a
{
  color:#3d2f01;
  text-decoration: none;

}

.footer-links.inline li
{
  display:inline-block;
  
}
hr{
  background: rgb(46, 44, 44);
}
.copyright-text
{
  margin:0;
  text-align:center;
  margin-left: -10px;
  font-weight: bolder;
}


 .nav-parent{
  height: 60px;
  margin-Top: 90px;
  padding-Left: 10px;
  cursor: pointer;
  align-Items: center;
  align-Content: center;
  text-Align: center;
  /* margin-Content: "center; */

  margin-Left: 15%  ;
 
  
 }
 @media (min-width:350px) and (max-width:374px){
   .nav-prod{
    /* margin-top: -2vw;
    padding-left: 27px; */
    /* padding-inline: 29.4px; */
    /* margin-right: -.5vw;
    margin-left: -8.2vw; */
    font-size: 0.55em;
    
    margin-top: -1%;
    justify-content: center;
  margin-left: -2.9vw;

    

    
  } 
  .nav-parent{
    /* margin-left:  5.3vw;
    display: flex;
    justify-content: row; */
    
    margin-left:  3vw;
    
    padding: 18px;
    width: 100%;
    /* justify-content: center; */
    overflow-x: hidden;
    height: 100%;
    
    
  }
 
  .products{
    
    width: 31px;
    height:31px;
  }

.site-footer{
  height: 230px;
  margin-bottom: -80.7vw;
  
  
}
  .site-footer h6{
    margin-left: -30.8vw;
  }
  .footer-links a{
    margin-left: 13vw;
  }
  .row-1{
    margin-left: -49.9vw;
  }
  body{
    
    overflow-x: hidden;
    
    
  }
  .ab{
    display: flex;
    margin-top: auto;
justify-content: grid;
flex-direction: column;
  }
  
 }
 @media (min-width:375px) and (max-width:404px){
  .nav-prod{
    /* margin-top: -2vw; */
     /* padding-left: 27px; 
     padding-inline: 29.4px;  */
     /* margin-right: 1%;
    margin-left: 1%;  */
    font-size: 0.6em;
    /* display: flex;
    justify-content: center; */
    /* flex-direction: column; */
    /* flex-wrap: wrap;
    flex-basis: 100; */
    /* flex-flow: inherit; */
    /* padding: 18px;
    width: 100%;
  
    overflow-x: hidden; */

      margin-top: -1%;
      justify-content: center;
    margin-left: -2.1vw;

    
  }
  
  .nav-parent{
    
    
    /* padding: 18px; */
  
  
    /* overflow-x: hidden; */
    margin-left:  5vw;
    width: 100%;
    height: 100%;
    /* justify-content: center; */
    
  }
  
  .products{
    
    width: 31px;
    height:31px;
  }

.site-footer{
  height: 230px;
  margin-bottom: -74.8vw;
  
  
}
  .site-footer h6{
    margin-left: -31.9vw;
  }
  .footer-links a{
    margin-left: 8.8vw;
  }
  body{
    
    overflow-x: hidden;
    
    
    
  }
  .row-1{
    margin-left: -45.9vw;
  }
  
}
 @media (min-width:405px) and (max-width:431px){
  .nav-prod{
    /* margin-top: -2vw;
    padding-left: 27px;
    padding-inline: 29.4px;
    margin-right: 6vw;
    margin-left: -13.1vw; */
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: -1.5vw;
 

    
      
    

    
  }
  .nav-parent{
    margin-left:  5vw;
    width: 100%;
   
    height: 100%;
    
    
    
  }
  .products{
    
    width: 31.2px;
    height:31.2px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -69.8vw;
    
    
  }
  .site-footer h6{
    margin-left: -26.8vw;
  }
  .footer-links a{
    margin-left: 10vw;
  }
  body{
    
    overflow-x: hidden;
    
    
  }
  .row-1{
    margin-left: -43.9vw;
  }
}
 @media (min-width:432px) and (max-width:450px){
  .nav-prod{
    /* margin-top: -2vw;
    padding-left: 27px;
    padding-inline: 26.9px;
    margin-right: -.5vw;
    */
   
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 0vw;
 
    
      
    

    
  }
  .nav-parent{
   
    margin-left:  5vw;
    width: 100%;
    height: 100%;
   
    
    
    
  }
  .products{
    
    width: 31.2px;
    height:31.2px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -66.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -25.8vw;
  }
  .footer-links a{
    margin-left: 8vw;
  }
  body{
    
    overflow-x: hidden;
    
    
  }
  .row-1{
    margin-left: -41.9vw;
  }
}
 @media (min-width:451px) and (max-width:471px){
  .nav-prod{
    
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 0vw;
 
    
      
    

    
  }
  .nav-parent{
    margin-left:  5vw;
    width: 100%;
    height: 100%;
    
    
    
    
    
  }
  .products{
    
    width: 31.2px;
    height:31.2px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -63.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -23.8vw;
  }
  .footer-links a{
    margin-left: 8.5vw;
  }
  body{
    
    overflow-x: hidden;
    
    
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:472px) and (max-width:499px){
  .nav-prod{
    font-size: 0.7em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 1.9vw;
 
    

    
  }
  .nav-parent{
    margin-left:  5vw;
    width: 100%;
   height: 100%;
  
    
    
    
    
  }
  body{
    
    overflow-x: hidden;
    
    
  }
  .products{
    
    width: 31.2px;
    height:31.2px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -59.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -21.8vw;
  }
  .footer-links a{
    margin-left: 7vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:500px) and (max-width:521px){
  .nav-prod{
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.5vw;
    
      
    

    
  }
  .nav-parent{
    margin-left:  -2vw;
    width: 100%;
   height: 100%;
   justify-content: center;
  
    
    
  }
  .products{
    
    width: 31.5px;
    height:31.5px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -57.4vw;
    
    
  }
  .site-footer h6{
    margin-left: -19.8vw;
  }
  .footer-links a{
    margin-left: 7.5vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 
 @media (min-width:522px) and (max-width:539px){
  .nav-prod{
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.9vw;
    
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
  }
  .products{
    
    width: 31.5px;
    height:31.5px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -55.4vw;
    
    
  }
  .site-footer h6{
    margin-left: -17.8vw;
  }
  .footer-links a{
    margin-left: 8vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:540px) and (max-width:550px){
  .nav-prod{
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.5vw;
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
  }
  .products{
    
    width: 31.5px;
    height:31.5px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -54.4vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 9.9vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:551px) and (max-width:574px){
  .nav-prod{
    font-size: 0.68em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.5vw;
      
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
  }
  .products{
    
    width: 32.5px;
    height:32.5px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -52.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 8.9vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:575px) and (max-width:600px){
  .nav-prod{
    font-size: 0.7em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.7vw;
      

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
  }
  .products{
    
    width: 32.5px;
    height:32.5px;
  }
  .site-footer{
    height: 240px;
    margin-bottom: -50.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 8.4vw;
  }
  .row-1{
    margin-left: -37.9vw;
  }
}
 @media (min-width:601px) and (max-width:625px){
  .nav-prod{
    font-size: 0.75em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.9vw;
      
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
    
  }
  .products{
    
    width: 32.5px;
    height:32.5px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -48.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 8vw;
  }
  .row-1{
    margin-left: -33.9vw;
}
 }
 @media (min-width:626px) and (max-width:627px){
  .nav-prod{
    font-size: 0.75em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 2.9vw;
      
    
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
   
  }
  .products{
    width: 33px;
    height:33px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -46.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 7.7vw;
  }
  .row-1{
    margin-left: -49.9vw;
  }
  .copyright-text{
    margin-left: -21vh;
  }
}
 
 @media (min-width:628px) and (max-width:647px){
  .nav-prod{
    font-size: 0.75em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 3.2vw;
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
  }
  .products{
    width: 33px;
    height:33px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -46.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 7.7vw;
  }
  .row-1{
    margin-left: -21.9vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
    
  }
}

@media (min-width:648px) and (max-width:678px){
  .nav-prod{
    font-size: 0.75em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 3.7vw;
    
      
    
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
  }
  .products{
    width: 35px;
    height:35px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -44.2vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 7.7vw;
  }
  .row-1{
    margin-left: -20.9vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}
@media (min-width:679px) and (max-width:703px){
  .nav-prod{
    font-size: 0.75em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 4.2vw;
    
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
  }
  .products{
    width: 35px;
    height:35px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -42.5vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.8vw;
  }
  .footer-links a{
    margin-left: 7.7vw;
  }
  .row-1{
    margin-left: -20vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}
 @media (min-width:704px) and (max-width:729px){
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 4.2vw;
    
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
    
   
  }
  .products{
    width: 35px;
    height:35px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -41.5vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.4vw;
  }
  .footer-links a{
    margin-left: 6.5vw;
  }
  .row-1{
    margin-left: -20vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}
 @media (min-width:730px) and (max-width:767px){
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 4.8vw;
    
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
    
  }
  .products{
    width: 35px;
    height:35px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -38.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.4vw;
  }
  .footer-links a{
    margin-left: 6.5vw;
  }
  .row-1{
    margin-left: -20vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}
 @media (min-width:768px) and (max-width:782px){
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.1vw;
    
    
      
    

    
  }
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
  .products{
    width: 35px;
    height:35px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -38.1vw;
    
    
  }
  .site-footer h6{
    margin-left: -4.3vw;
  }
  .footer-links a{
    margin-left: 9.9vw;
  }
  .row-1{
    margin-left: -26vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}
@media (min-width:783px) and (max-width:794px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
    

    
  }
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.1vw;
  }
  .products{
    width: 40px;
    height: 40px;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -37.4vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.4vw;
  }
  .footer-links a{
    margin-left: 6.2vw;
  }
  .row-1{
    margin-left: -14vw;
  }
  .copyright-text{
    margin-left: 10.4vh;
  }
}

@media (min-width:795px) and (max-width:805px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
    

    
  }
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.2vw;
   
  }
  .products{
    width:42px;
    height: 42px;
  }
  .copyright-text{
    margin-top: 0vw;
    margin-left: 5vw;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -36.6vw;
    
    
  }
  .site-footer h6{
    margin-left: -8.4vw;
  }
  .footer-links a{
    margin-left: 7.9vw;
  }
  .copyright-text{
    margin-left: 25.9vh;
  }
  .row-1{
    margin-left: -10vw;
  }
}
@media (min-width:806px) and (max-width:820px){
  .nav-parent{
    
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
    
      
    

    
  }
  .nav-prod{
    font-size: 0.8em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.4vw;
  }
  .products{
    width:42px;
    height: 42px;
  }
  .copyright-text{
    margin-top: 0vw;
    margin-left: 0vw;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -35.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -9vw;
  }
  .footer-links a{
    margin-left: 7.5vw;
  }
  
  .row-1{
    margin-left: -24.9vw;
  }
}
@media (min-width:821px) and (max-width:835px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
      
    

    
  }
  .nav-prod{
    font-size: 0.85em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.4vw;
  }
  .products{
    width:45px;
    height: 45px;
  }
  .copyright-text{
    margin-top: 0vw;
    margin-left: 5vw;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -35.5vw;
    width: 104.9%;
    
  }
  .site-footer h6{
    margin-left: -17.4vw;
  }
  .footer-links a{
    margin-left: 3vw;
  }
  .copyright-text{
    margin-left: 15vh;
  }
  .row-1{
    margin-left: -10vw;
  }
}

@media (min-width:836px) and (max-width:849px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
    

    
  }
  .nav-prod{
    font-size: 0.85em;
    margin-top: -1%;
    justify-content: center;
  margin-left: 5.4vw;

  }
  .products{
    width:45px;
    height: 45px;
  }
  .copyright-text{
    margin-top: 0vw;
    margin-left: 5vw;
  }
  .site-footer{
    height: 225px;
    margin-bottom: -34.9vw;
    
    
  }
  .site-footer h6{
    margin-left: -12.4vw;
  }
  .footer-links a{
    margin-left: 1vw;
  }
  .copyright-text{
    margin-left: 15vh;
  }
  .row-1{
    margin-left: -10vw;
  }
}
@media  (min-height:1310px) {
  .nav-prod{
    margin-top:5vh;
  }
}
@media (min-width:850px) and (max-width:884px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
   
  }
    
.nav-prod{
  font-size: 0.85em;
  margin-top: -1%;
  justify-content: center;
margin-left: 5.6vw;

}
.copyright-text{
  margin-top: 0vw;
  margin-left: 5vw;
}
.site-footer{
  height: 225px;
  margin-bottom: -30.6vw;
  
}
.site-footer h6{
  margin-left: -15.4vw;
}
.footer-links a{
  margin-left: 3vw;
}
.copyright-text{
  margin-left: 5vh;
}
.row-1{
  margin-left: -15vw;
}
}
@media (min-width:885px) and (max-width:919px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
    
.nav-prod{
  font-size: 0.85em;
  margin-top: -1%;
  justify-content: center;
margin-left: 6vw;
  
}
.copyright-text{
  margin-top: 0vw;
  margin-left: 5vw;
}
.site-footer{
  height: 225px;
  margin-bottom: -32.5vw;
  
}
.site-footer h6{
  margin-left: -19.4vw;
}
.footer-links a{
  margin-left: 1vw;
}
.copyright-text{
  margin-left: 5vh;
}
.row-1{
  margin-left: -10vw;
}
body{
  padding-bottom: 300px;
}
}
@media (min-width:920px) and (max-width:950px){
  .nav-parent{
    margin-left: -2vw;
    height: 100%;
    width: 100%;
    justify-content: center;
  }
    
.nav-prod{ font-size: 0.85em;
  margin-top: -1%;
  justify-content: center;
margin-left: 6.4vw;

}

.copyright-text{
  margin-top: 0vw;
  margin-left: 5vw;
}
.site-footer{
  height: 225px;
  margin-bottom: -26.2vw;
  
}
.site-footer h6{
  margin-left: -17.4vw;
}
.row-1{
  margin-left: -10vw;
}
.footer-links a{
  margin-left: 1.5vw;
}
body{
  padding-bottom: 250px;
}
}
@media (min-width: 951px) and (max-width:991px){
  .nav-parent{
    margin-top: 8vw;
    margin-left: 2.5vw;
    margin-right: .3vw;
    padding-inline: 2.1vw;
    
  }
  .site-footer{
    margin-bottom: -23.3vw;
    /* height: 220px; */
    height: 210px;
  }
  .site-footer h6{
    margin-left: -18.2vw;
    
  }
  body{
    padding-bottom: 235px;
  }
  .row-1{
    margin-left: -12vw;
  }
  .footer-links a{
    margin-left: 1vw;
  }
  .copyright-text{
    margin-top: -1.7vw;
  }
  
}
@media (min-width:992px) and (max-width:1050px){
  .nav-parent{
    margin-left:6.5vw;
    margin-right: -.11vw;
    padding-inline: 1.9vw;
    padding-right: -6vw;
    
  }
  .site-footer{
    margin-bottom: -28.4vw;
    height: 215px;
    padding-bottom: 1vw;
  }
  .site-footer h6{
    margin-left: -17.9vw;
  }
  .row-1{
    margin-left: -10vw;
  }
  .footer-links a{
    margin-left: 1vw;
  }
 body{
   padding-bottom: 308px;
 }
 .copyright-text{
  margin-top: -1.5vw;
}

}
@media (min-width:1051px) and (max-width:1099px){
  .nav-parent{
    margin-left:6.5vw;
    margin-right: -.11vw;
    padding-inline: 1.9vw;
    padding-right: -6vw;
    
  }
  .site-footer{
    margin-bottom: -27.6vw;
    height: 212px;
  }
  .site-footer h6{
    margin-left: -16.9vw;
  }
  .row-1{
    margin-left: -10vw;
  }
  .footer-links a{
    margin-left: 1vw;
  }
 body{
   padding-bottom: 305px;
 }
 .copyright-text{
  margin-top: -1.5vw;
}

}
@media(min-width:1100px) and (max-width:1165px){
  .nav-parent{
    margin-left: 10vw;
  }
  .nav-prod{
    font-size: 1.05em;
  }
  .products{
    height: 51px;
    width: 51px;
  }
  .site-footer{
    margin-bottom: -25.8vw;
    height: 215px;
  }
  .site-footer h6{
    margin-left: -16.9vw;
  }
  .copyright-text{
    margin-top: -1.1vw;
  }
}
@media(min-width:1166px) and (max-width:1199px){
  .nav-parent{
    margin-left: 10vw;
  }
  .nav-prod{
    font-size: 1.05em;
  }
  .products{
    height: 51px;
    width: 51px;
  }
  .site-footer{
    margin-bottom: -24.9vw;
    height: 225px;
  }
  .site-footer h6{
    margin-left: -15.9vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  
}
@media(min-width:1200px) and (max-width:1230px){
  .nav-parent{
    margin-left: 10vw;
  }
  .nav-prod{
    font-size: 1.05em;
  }
  .products{
    height: 51px;
    width: 51px;
  }
  .site-footer{
    margin-bottom: -24.4vw;
    height: 220px;
  }
  .site-footer h6{
    margin-left: -13.9vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
}
@media(min-width:1231px) and (max-width:1380px){
  .nav-parent{
    margin-left: 12vw;
  }
  .nav-prod{
    font-size: 1.05em;
  }
  .products{
    height: 51px;
    width: 51px;
  }
  .products{
    height: 51px;
    width: 51px;
  }
  .site-footer{
    margin-bottom: -22.5vw;
    height: 220px;
  }
  .site-footer h6{
    margin-left: -12.3vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .row-1{
    margin-left: -9vw;
  }
}
@media(min-width:1381px) and (max-width:1399px){
  .nav-parent{
    margin-left: 14vw;
  }
  .nav-prod{
    font-size: 1.09em;
  }
  .products{
    height: 52.5px;
    width: 52.5px;
  }
  .site-footer{
    margin-bottom: -21.6vw;
    height: 220px;
  }
  .site-footer h6{
    margin-left: -12.3vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
}
@media(min-width:1400px) and (max-width:1481px){
  .nav-parent{
    margin-left: 14vw;
  }
  .nav-prod{
    font-size: 1.09em;
  }
  .products{
    height: 52.5px;
    width: 52.5px;
  }
  .site-footer{
    margin-bottom: -20.2vw;
    height: 220px;
  }
  .site-footer h6{
    margin-left: -11vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
}
@media(min-width:1482px) and (max-width:1582px){
  .nav-parent{
    margin-left: 16vw;
  }
  .nav-prod{
    font-size: 1.2em;
  }
  .products{
    height: 53px;
    width: 53px;
  }
  .site-footer{
    margin-bottom: -19.2vw;
    height: 225px;
  }
  .site-footer h6{
    margin-left: -11vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
}
@media(min-width:1583px) and (max-width:1683px){
  .nav-parent{
    margin-left: 17vw;
  }
  .nav-prod{
    font-size: 1.3em;
  }
  .products{
    height: 54px;
    width: 54px;
  }
  .site-footer{
    margin-bottom: -18vw;
    height: 225px;
  }
  .site-footer h6{
    margin-left: -10vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
}
@media(min-width:1684px) and (max-width:1784px){
  .nav-parent{
    margin-left: 18vw;

  }
  .nav-prod{
    font-size: 1.35em;
  }
  .products{
    height: 55px;
    width: 55px;
  }
  .site-footer{
    margin-bottom: -16.8vw;
    height: 230px;
  }
  .site-footer h6{
    margin-left: -9vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .footer-links a{
    margin-left: .5vw;
  }
}
@media(min-width:1785px) and (max-width:1885px){
  .nav-parent{
    margin-left: 19vw;
  }
  .nav-prod{
    font-size: 1.4em;
  }
  .products{
    height: 57px;
    width: 57px;
  }
  .site-footer{
    margin-bottom: -15.8vw;
    height: 230px;
  }
  .site-footer h6{
    margin-left: -9vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .footer-links a{
    margin-left: .5vw;
  }
}
@media(min-width:1886px) and (max-width:1986px){
  .nav-parent{
    margin-left: 20vw;
  }
  .nav-prod{
    font-size: 1.45em;
  }
  .products{
    height: 58px;
    width: 58px;
  }
  .site-footer{
    margin-bottom: -15vw;
    height: 230px;
  }
  .site-footer h6{
    margin-left: -8vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .footer-links a{
    margin-left: .9vw;
  }
}
@media(min-width:1987px) and (max-width:2087px){
  .nav-parent{
    margin-left: 21vw;
  }
  .nav-prod{
    font-size: 1.5em;
  }
  .products{
    height: 59px;
    width: 59px;
  }
  
  .site-footer{
    margin-bottom: -14.6vw;
    height: 230px;
  }
  .site-footer h6{
    margin-left:-6.5vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .footer-links a{
    margin-left: 1.2vw;
  }
  .row-1{
    margin-left: -8vw;
  }
}
@media(min-width:2088px){
  .nav-parent{
    margin-left: 22vw;
  }
  .nav-prod{
    font-size: 1.6em;
    margin-top: 1.5vw;
  }
  .products{
height: 62px;
width: 62px;
  }
 
  .site-footer{
    margin-bottom: -12.6vw;
    height: 230px;
    font-size: 1.2rem;
  }
  .site-footer h6{
    margin-left: -6vw;
  }
  .copyright-text{
    margin-top: -.5vw;
  }
  .footer-links a{
    margin-left: 1.2vw;
  }
  .row-1{
    margin-left: -8vw;
  }
}