@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  background: #fff; /* fallback for old browsers */
  overflow-x: hidden;
  margin:0;
  margin-top:10.3%;
  padding-top: 2.5%;
  /* margin-left:-1px; */
}
* {
  margin: 0;
  padding: 0;
}
i {
  margin-right: 10px;
}
.navbar-logo {
  padding: 15px;
  color: #fff;
  pointer-events: none;
}
.nav-item{
  align-items: right;
}
.navbar-mainbg {
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5fHTkU4_lY-Mp2rNaBLExYromjP8ZJiMzDw&usqp=CAU);
  padding: 0px;
}
#navbarSupportedContent {
  overflow: hidden;
  position: relative;
  align-items: right;
  align-content: flex;
  flex-direction: column;
  flex-wrap: inherit;
  flex-flow: row-reverse;
  margin-right: 3%;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
  align-items: right;
}
#navbarSupportedContent ul li a i {
  margin-right: 10px;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
}
#navbarSupportedContent ul li a {
  color: rgb(12, 12, 12);
  text-decoration: none;
  font-size: 15px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}
#navbarSupportedContent > ul > li.active > a {
  color: #000000;
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: '\f105';
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  font-family: 'Font Awesome 5 Free';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}

.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 10px;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 10px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5fHTkU4_lY-Mp2rNaBLExYromjP8ZJiMzDw&usqp=CAU);;
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}
 @media (max-width: 991px) {

  #navbarSupportedContent ul li a {
    padding: 12px 30px;
  }
  .hori-selector {
    margin-top: 0px;
    margin-left: 10px;
    border-radius: 0;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .hori-selector .left,
  .hori-selector .right {
    right: 10px;
  }
  .hori-selector .left {
    top: -25px;
    left: auto;
  }
  .hori-selector .right {
    bottom: -25px;
  }
  .hori-selector .left:before {
    left: -25px;
    top: -25px;
  }
  .hori-selector .right:before {
    bottom: -25px;
    left: -25px;
  }
}


.quotestion{
  z-index:2000;
  background: transparent; 
  /* display:flex;
  flex-direction:column;
  flex-flow: column;
  flex-wrap: inherit ;
  align-content: center; */
  color: white; 
  box-sizing: border-box;
margin-right: 17px;
height: 50px;
margin-top: 9px;
border-radius:5;
}
.quotestionform{
  z-index: 1000;
}
/* .nav-item {
  height: 60px;
  align-items: center;

}
.nav-link-a{
  margin-top: -42.5px;
} */
/* .nav-item {
  height: 60px;
  margin-right:15%;

} */
.furnlog{
  height: 100px;
padding : 0;
}
.furnlog:hover{
  transform: scale(3);
  transition:0.5s ease-in;
}
/* .container img{


} */
/* .navbar-logo:hover{
  transform: scale(100);
  transition:0.5s ease;
} */



/* hey herre we are with navbar and slider */
/* .carousel-item {
	height: 100vh;
	min-height: 300px;
}
.carousel-caption {
	bottom: 220px;
}
.carousel-caption h5 {
	font-size: 45px;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-top: 25px;
	font-family: merienda;
}
.carousel-caption p {
	width: 60%;
	margin: auto;
	font-size: 18px;
	line-height: 1.9;
	font-family: poppins;
}
.carousel-caption a {
	text-transform: uppercase;
	background: #262626;
	padding: 10px 30px;
	display: inline-block;
	color: #fff;
	margin-top: 15px;
} */

.navbar-nav a {
	font-family: poppins;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: bold;
}
.navbar-light .navbar-brand {
	color: #fff;
	font-size: 25px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	color: #fff;
}
.navbar-light .navbar-nav .nav-link {
	color: #fff;
}
.navbar-nav {
	text-align: center;
}
.nav-link {
	padding: .2rem 1rem;
}
.nav-link.active, .nav-link:focus {
	color: #fff;
}
.navbar-toggler {
	padding: 1px 5px;
	font-size: 18px;
	line-height: 0.3;
	background: #fff;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color: #fff;
}
.w-100 {
	height: 50vh;
  
}
.jaimatadi{
  font-family: cursive;
  font-size: 23px;
  font-weight: 600;
}
.navbar{
  position: relative;
  /* margin-bottom: 10vw; */
  width: 100%;
  height: 50%;
  
  /* background-image: url(https://i.pinimg.com/originals/60/6a/6e/606a6e2eab2ca9da6e573dab68c2af46.jpg); */
 background-image: url("./images/navbarimg.jpg"); 
/* background-image: url(https://media.istockphoto.com/photos/white-hexagons-mosaic-3d-render-picture-id941875534?b=1&k=20&m=941875534&s=170667a&w=0&h=h1QeZDyjv7Ml5PmHbIVNQ936PxQGuvdBKXmIoPey03s=); */
}
.navbar-brand img{
  height:100px;
  display:flex;
  justify-content: flex-start;
  
  /* margin-left :-4vw; */
 }
 /*  */
@media only screen and (max-width: 767px) {
	.navbar-nav.ml-auto {
		background: rgba(0, 0, 0, 0.5);
	}
	.navbar-nav a {
		font-size: 14px;
		font-weight: normal;
	}
}
 
@media (min-width:640px) and (max-width:660px){
  body{
    margin-top:-57.2% !important;
    padding-left: 0%;
  }

}
@media (min-width:660px) and (max-width:685px){
  body{
    margin-top:-57.5% !important;
    padding-left: 0%;
  }
}

@media (min-width:685px) and (max-width:699px){
  body{
    margin-top:-56.9% !important;
    padding-left: 0%;
  }

}
@media (min-width:700px) and (max-width:724px){
  body{
    margin-top:-20.9% !important;
    padding-left: 0%;
  }

}

@media (min-width:725px) and (max-width:745px){
  body{
    margin-top:-17.9% !important;
    padding-left: 0%;
  }

}
@media (min-width:745px) and (max-width:770px){
  body{
    margin-top:-19.9% !important;
    padding-left: 0%;
  }

}
@media (min-width:770px) and (max-width:785px){
  body{
    margin-top:-5.2% !important;
    padding-left: 0%;
  }

}
@media (min-width:785px) and (max-width:825px){
  body{
    margin-top:-4.5% !important;
    padding-left: 0%;
  }

}
@media (min-width:825px) and (max-width:850px){
  body{
    margin-top:-2.9% !important;
    padding-left: 0%;
  }

}
@media (min-width:850px) and (max-width:890px){
  body{
    margin-top:-5.5% !important;
    padding-left: 0%;
  }

}
@media (min-width:890px) and (max-width:919px){
  body{
    margin-top:-4% !important;
    padding-left: 0%;
  }

}
@media (min-width:920px) and (max-width:930px){
  body{
    margin-top:-10% !important;
    padding-left: 0%;
  }

}
@media (min-width:930px) and (max-width:970px){
  body{
    margin-top:-9.6% !important;
    padding-left: 0%;
  }

}
@media (min-width:970px) and (max-width:991px){
  body{
    margin-top:-8.4% !important;
    padding-left: 0%;
  }

}
@media (min-width:992px) and (max-width:1020px){
  body{
    margin-top:4.4% !important;
    padding-left: 0%;
  }

}
@media (min-width:1020px) and (max-width:1115px){
  body{
    margin-top:4.6% !important;
    padding-left: 0%;
  }

}
@media (min-width:1115px) and (max-width:1150px){
  body{
    margin-top:4% !important;
    padding-left: 0%;
  }

}
@media (min-width:1150px) and (max-width:1199px){
  body{
    margin-top:3.6% !important;
    padding-left: 0%;
  }

}
@media (min-width:1200px) and (max-width:1220px){
  body{
    margin-top:4% !important;
    padding-left: 0%;
  }

}
@media (min-width:1220px) and (max-width:1285px){
  body{
    margin-top:3.9% !important;
    padding-left: 0%;
  }

}
@media (min-width:1285px) and (max-width:1350px){
  body{
    margin-top:3.5% !important;
    padding-left: 0%;
  }

}
@media  (min-width:1350px) and (max-width:1380px) {
  body{
    margin-top: 3.8% ;
  }
} 
 @media  (min-width:1381px) and (max-width:1384px) {

  body{
    margin-top: 3.2% ;
  }
}  
 @media  (min-width:1385px) and (max-width:1430px) {

  body{
    margin-top: 3.3% ;
  }
}  
 @media (min-width:1430px) and (max-width:1469px){
  
  body{
    margin-top:2.4% ;
  }
}
@media (min-width:1470px) and (max-width:1500px){
  body{
    margin-top:1.8%;
  }
} 
@media (min-width:1500px) and (max-width:1530px){
  body{
    margin-top: 1.7%;
  }
}
@media (min-width:1530px) and (max-width:1580px){
  body{
    margin-top: 2% !important;
  }
}
@media (min-width:1580px) and (max-width:1620px){
  body{
    margin-top: 1.3% !important;
  }
}
@media (min-width:1621px) and (max-width:1670px){
  body{
    margin-top: 1.7% !important;
  }
}

@media (min-width:1670px) and (max-width:1720px){
  body{
    margin-top: .7% !important;
  }
}
@media (min-width:1720px) and (max-width:1780px){
  body{
    margin-top: 1% !important;
  }
}

@media (min-width:1781px) and (max-width:1840px){
  body{
    margin-top: .5% !important;
  }
}

@media (min-width:1840px) and (max-width:1900px){
  body{
    margin-top: .3% !important;
  }
} 
@media (min-width:1900px) and (max-width:1960px){
  body{
    margin-top: .3% !important;
  }
} 
@media (min-width:1960px) and (max-width:2040px){
  body{
    margin-top: .3% !important
  }
} 
@media (min-width:2040px) and (max-width:2087px){
  body{
    margin-top: .3% !important;
  }
} 
@media (min-width:2088px)and (max-width:2170px){
  body{
    margin-top: -1.3% !important;
  }
}
@media (min-width:2170px)and (max-width:2270px){
  body{
    margin-top: -1% !important;
  }
}
@media (min-width:2270px)and (max-width:2320px){
  body{
    margin-top: -.6% !important;
  }
}
@media (min-width:2320px)and (max-width:2370px){
  body{
    margin-top: -.2% !important;
  }
}
@media (min-width:2370px)and (max-width:2420px){
  body{
    margin-top: 0% !important;
  }
}
@media (min-width:2420px)and (max-width:2500px){
  body{
    margin-top: 0.5% !important;
  }
}
@media (min-width:2500px){
  body{
    margin-top: 1% !important;
  }
}
@media (min-width: 800px) and (max-width:990px) {
  .navbar{
    margin-top:9% !important;
  }
  }
  @media (min-width: 640px) and (max-width:700px) {
  .navbar{
    margin-top:65vw !important;
  }
  }
  @media (min-width: 769px) and (max-width:800px) {
  .navbar{
    margin-top:12vw !important;
  }
  }
  @media (min-width: 700px) and (max-width:769px) {
  .navbar{
    margin-top:27vw !important;
  }
  }
  @media (min-width: 620px) and (max-width:640px) {
  .navbar{
    margin-top:-45vw !important;
  }
  }
  @media (min-width: 600px) and (max-width:620px) {
  .navbar{
    margin-top:-46% !important;
  }
  }
  @media only screen and (min-width: 550px) and (max-width:600px) {
  .navbar{
    margin-top:-49% !important;
  }
  }
  @media only screen and (min-width: 495px) and (max-width:550px) {
  .navbar{
    margin-top:-53% !important;
  }
  }
  @media only screen and (min-width: 477px) and (max-width:495px) {
  .navbar{
    margin-top:-55% !important;
  }
  }
  @media only screen and (min-width: 437px) and (max-width:477px) {
  .navbar{
    margin-top:-58% !important;
  }
  }
  
  @media only screen and (min-width: 421px) and (max-width:437px) {
  .navbar{
    margin-top:-60% !important;
  }
  }
  
  @media only screen and (min-width: 398px) and (max-width:421px) {
  .navbar{
    margin-top:-63% !important;
  }
  }
  
  @media only screen and (min-width: 380px) and (max-width:398px) {
  .navbar{
    margin-top:-65% !important;
  }
  }
  @media only screen and (min-width: 350px) and (max-width:380px) {
  .navbar{
    margin-top:-67.5% !important;
  }
  }
  @media only screen and (min-width: 225px) and (max-width:350px) {
  .navbar{
    margin-top:-70% !important;
  }
  } 
